import React from "react"
import styled, {css} from "styled-components"

// components
import {Container} from "../components/Container"
import {Nav} from "../components/Nav"
import RenderIf from "../components/RenderIf"
import Title from "../components/Title"
import {useTamanhoTela} from "../hooks/useTamanhoTela"
import PreTitle from "../components/PreTitle";
import MarkedText from "../components/MarkedText"
import SplineObject from "../components/SplineObject"
import LogosCarrousel from "../components/LogosCarrousel"
import P from "../components/P"
import BudgetStrap from "../components/BudgetStrap"
import NumberAnimated from "../components/NumberAnimated"
import ButtonLink from "../components/ButtonLink"
import Card from "../components/Card"
import RobotAction from "../components/RobotAction"
import Footer from "../components/Footer"

import BgGestao from "../assets/media/bg-gestao.png"
import BgAutomacao from "../assets/media/bg-automacao.png"
import BgVendas from "../assets/media/bg-vendas.png"


function Home () {
    
    const {isMobile} = useTamanhoTela()
   
      
    return (
        <>
            <Nav />

            <Container 
                css="
                    width: 100%; 
                    display: flex; 
                    align-items: stretch; 
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-top: -50px;
                "
            >

                <Container
                    id="top"
                    key={1}
                    css={css`
                        display:flex; 
                        align-items: center; 
                        justify-content: space-between;  
                        width: 80vw;
                        height: 100%;
                        gap: 2%;

                        @media only screen and (max-width: 600px) {
                            flex-direction: column;
                            gap: 78px;
                        }
                    `}
                >
                    <Container
                        key={2}
                        css={css`
                            display:flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            align-items: start;
                            gap:16px;
                            justify-content: flex-start;
                            width: 50%;

                            @media only screen and (max-width: 600px) {
                                margin-top:55%;
                                width: 100%;
                                align-items: flex-start;

                            }
                        `}
                            
                    >
                        <PreTitle>Cansado de ver ideias brilhantes ficarem no papel e não se transformarem em negócios? Vamos mudar isso juntos!</PreTitle>
                        <Title><MarkedText>Transforme</MarkedText> sua ideia em software em até <MarkedText>90 dias</MarkedText>, conosco!</Title>
                        <ButtonLink size="94%">Solicitar orçamento</ButtonLink>

                    </Container>

                    <RenderIf condicao={!isMobile}>
                        <Container
                            key={3}
                            css={css`
                                display:flex;
                                flex-wrap: wrap;
                                align-items: center; 
                                justify-content: flex-end;
                                width: 50%;
                                height: 100vh;

                                @media only screen and (max-width: 600px) {
                                    height: 60%;
                                    width: 100%;
                                    margin-bottom: 16%;
                                }
                            `}
                        >
                            <SplineObject url="https://prod.spline.design/DU1-PXJUWNqbpc0T/scene.splinecode"/>
                        </Container>
                    </RenderIf>

                    
                
                </Container>

                <LogosCarrousel/>

                <BudgetStrap/>
                
                {/* inicio sobre */}
                <Container
                key={4}
                    css={css`
                        display:flex; 
                        align-items: center; 
                        justify-content: space-between;  
                        width: 80vw;
                        gap: 2%;

                        @media only screen and (max-width: 600px) {
                            flex-direction: column;
                            justify-content: flex-start;
                            width: 90vw;
                        }
                    `}
                        
                >

                    <Container
                        css={css`
                            display:flex;
                            flex-wrap: wrap;
                            align-items: center; 
                            justify-content: space-between;
                            width: 100%;
                            margin-top: 100px;
                            margin-bottom: 100px;

                            @media only screen and (max-width: 600px) {
                                width: 100%;
                                flex-direction: column;
                                align-items: start;
                            }
                        `}
                    >
                        <Container
                            id="about"
                            css={css`
                                display:flex;
                                width: 100%;
                                align-items: start;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                            `}
                            
                        >
                            <PreTitle>Conheça melhor quem tirará a sua ideia do papel.</PreTitle>
                            <Title>Sobre a <MarkedText>Mango.</MarkedText></Title>
                        </Container>
                            

                        <Container
                            css={css`
                                display:flex;
                                width: 50%;
                                flex-direction: column;
                                gap: 12px;
                                align-items: center;
                                justify-content: flex-start;
                                margin-top: 30px;

                                @media only screen and (max-width: 600px) {
                                    width: 100%;
                                    
                                }
                            `}
                            
                        >
                            <P size="18px">
                                Somos sua parceira em soluções digitais de ponta! Com mais de três anos de experiência no mercado e presença no Brasil e em Portugal, a Mango é uma software house apaixonada por transformar ideias em realidade digital.
                                <br/>
                                <br/>
                                Com uma equipe experiente e comprometida estamos prontos para tornar sua ideia em realidade! Desenvolvemos soluções em automação, gestão, marketing, vendas, e mais...
                                <br/>
                                <br/>
                                Seja qual for a sua visão digital, a Mango está pronta para torná-la uma realidade. Junte-se a nós e descubra o poder da tecnologia bem aplicada.
                            </P>

                           
                        </Container>

                        <Container
                            css={css`
                                display:flex;
                                flex-direction: column;
                                width: 50%;
                                align-items: center;
                                justify-content: center;

                                @media only screen and (max-width: 600px) {
                                    width: 100%;
                                }
                            `}
                            
                        >
                            <NumberAnimated number={3} text="Estados atendidos"/>
                            <NumberAnimated number={2} text="Países atendidos"/>
                            <NumberAnimated number={12} text="Projetos concluídos"/>

                        </Container>
                    </Container>
                </Container>
                {/*fim  sobre */}


                {/* inicio servicos */}
                <Container
                        css={css`
                            display:flex; 
                            align-items: center; 
                            justify-content: space-between;  
                            width: 80vw;
                            gap: 2%;

                            @media only screen and (max-width: 600px) {
                                flex-direction: column;
                                justify-content: flex-start;
                                width: 90vw;
                            }
                        `}
                            
                    >
                        <Container
                            id="services"
                            css={css`
                                display:flex;
                                flex-wrap: wrap;
                                align-items: center; 
                                justify-content: space-between;
                                width: 100%;
                                margin-top: 100px;
                                margin-bottom: 100px;

                                @media only screen and (max-width: 600px) {
                                    margin-top: 0;
                                    width: 100%;
                                    flex-direction: column;
                                    align-items: center;
                                }
                            `}
                        >
                            <Container
                                css={css`
                                    display:flex;
                                    width: 100%;
                                    align-items: flex-start;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-wrap: wrap;
                                `}
                                
                            >
                                <PreTitle>Como podemos te ajudar?</PreTitle>
                                <Title>Conheça nossos <MarkedText>serviços.</MarkedText></Title>

                                <Container
                                css={css`
                                    display:flex;
                                    width: 100%;
                                    margin-top: 64px;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-wrap: wrap;

                                    @media only screen and (max-width: 600px) {
                                        width: 100%;
                                        flex-direction: column;
                                        align-items: center;
                                        gap: 32px;
                                    }
                                `}
                                
                                >
                                    <Card title="Soluções em Gestão" bg={BgGestao} url="https://api.whatsapp.com/send?phone=8498220309&text=Olá,%20vim%20através%20do%20site%20da%20Mango.%20Busco%20uma%20solução%20para%20gestão.">  
                                        <P color="#fefefe" weight="500">
                                            Não se desgaste gerindo seu negócio através de planilhas e cadernos.
                                            <br/>
                                            <br/>
                                            Desenvolvemos seu software de gestão personalizado de forma rápida e segura.
                                        </P>
                                    </Card>

                                    <Card title="Soluções em Automação" bg={BgAutomacao} url="https://api.whatsapp.com/send?phone=8498220309&text=Olá,%20vim%20através%20do%20site%20da%20Mango.%20Busco%20uma%20solução%20para%automação.">  
                                        <P color="#fefefe" weight="500">
                                            Já perdeu tempo fazendo uma tarefa chata e repetitiva? Isso não precisa acontecer!
                                            <br/>
                                            <br/>
                                            Criamos a sua automação completa voltada para qualquer área, com dashboards, alertas e muito mais.
                                        </P>
                                    </Card>

                                    <Card title="Soluções em Vendas" bg={BgVendas} url="https://api.whatsapp.com/send?phone=8498220309&text=Olá,%20vim%20através%20do%20site%20da%20Mango.%20Busco%20uma%20solução%20para%vendas.">  
                                        <P color="#fefefe" weight="500">
                                            Se perde ao gerenciar sua vendas? Esquece de dar retorno ao cliente interessado, ou mesmo não lembra o status do lead?
                                            <br/>
                                            <br/>
                                            Esses problemas somem ao ter a sua solução personalizada desenvolvida pela mango!
                                        </P>
                                    </Card>
                                </Container>


                            </Container>

                        </Container>
                    </Container>
                    {/* fim servicos */}

                    {/* inicio produtos */}
                    <Container
                        id="products"
                        css={css`
                            display:flex; 
                            align-items: center; 
                            justify-content: space-between;  
                            width: 80vw;
                            gap: 2%;

                            @media only screen and (max-width: 600px) {
                                flex-direction: column;
                                justify-content: flex-start;
                                width: 90vw;
                            }
                        `}
                            
                    >
                        <Container
                            css={css`
                                display:flex;
                                width: 100%;
                                align-items: center;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                                margin-bottom: 128px;
                                margin-top: 64px;

                                @media only screen and (max-width: 600px) {
                                    margin-top: 0;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    width: 90vw;
                                    text-align: center;
                                }

                            `}
                            
                        >
                            <PreTitle>Vem aí...</PreTitle>
                            <Title>O <MarkedText>MangoCRM</MarkedText> está em desenvolvimento.</Title>
                            <PreTitle>...aguarde</PreTitle>
                        </Container>

                    </Container>
                    {/* fim produtos */}

                    {/* inicio contato */}
                    {/* <Container
                        id="contacts"
                        css={css`
                            display:flex;
                            flex-direction: column;
                            align-items: center; 
                            justify-content: center;  
                            width: 80vw;
                            gap: 2%;

                            @media only screen and (max-width: 600px) {
                                flex-direction: column;
                                justify-content: flex-start;
                                width: 90vw;
                            }
                        `}
                            
                    >
                        <Container
                            css={css`
                                display:flex;
                                width: 100%;
                                align-items: center;
                                flex-direction: column;
                                justify-content: center;
                                flex-wrap: wrap;
                                margin-bottom: 128px;
                                margin-top: 64px;

                            `}
                            
                        >
                            <PreTitle>Fale conosco</PreTitle>
                            <Title>Contato</Title>
                        </Container>

                        <Container
                            css={css`
                                display:flex;
                                width: 100%;
                                align-items: center;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                                margin-bottom: 128px;
                                margin-top: 64px;

                            `}
                            
                        >
                        </Container>

                    </Container> */}
                    {/* fim contato */}

                {/* <RenderIf condicao={isMobile}>
                    <RobotAction/>
                </RenderIf> */}

            </Container>

            <Footer/>

        </>

    )
}

export { Home }
export default Home