import React from "react"
import styled from "styled-components"

const StyledP = styled.p`
    color: ${(props) => props.color};
    font-size: ${(props) => props.size};
    font-weight: ${(props) => props.weight};
    text-align: justify;


`

function P ({children, color, size, weight}) {
    return (
        <StyledP color={color} size={size } weight={weight}>
            {children}
        </StyledP>
    )
}

export {P}
export default P