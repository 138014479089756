import React from "react"
import styled from "styled-components"

const StyledMarkedText = styled.span`
    all: unset;
    background-color: #e72c71;
    color: #fefefe;
    margin: 0 3px;
    padding: 0 3px;
    border-radius: 10px;
`

function MarkedText ({children}) {
    return (
        <StyledMarkedText>
            {children}
        </StyledMarkedText>

    )
}

export {MarkedText}
export default MarkedText