import React from "react"
import styled, {keyframes} from "styled-components"

const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const StyledButton = styled.a`
    all: unset;
    border-radius: 10px;
    background-color: #eb5931; /* Cor inicial do gradiente */
    background-image: linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045); /* Gradiente linear */
    background-size: 200% 200%; /* Tamanho do gradiente */
    color: #fefefe;
    width: ${props => props.size ? props.size : '180px'};
    height: 30px;
    text-align: center;
    cursor: pointer;
    transition: font-weight 0.3s ease;
    animation: ${gradientAnimation} 6s ease infinite;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        font-weight: bold;
    }

    
`

function ButtonLink ({children, size}) {
    return (
        <StyledButton size={size} href="https://api.whatsapp.com/send?phone=8498220309&text=Olá,%20vim%20através%20do%20site%20da%20Mango." target="_blank">{children}</StyledButton>
    )
}

export {ButtonLink}
export default ButtonLink