import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import P from "./P";

const StyledContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: 52px;
    font-weight: bold;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)}; /* Aplica a animação de aparecer */
    transition: opacity 0.5s ease-in-out; /* Adiciona uma transição suave */
`

const StyledNum = styled.span`
    font-size: 52px;
    font-weight: bold;
    background-color: #e72c71;
    color: #fefefe;
    padding: 0 16px;
    text-align: center;
    margin-right: 16px;
`;

function NumberAnimated({ text, number }) {
  const [num, setNum] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const { top, bottom } = ref.current.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      // Verifica se o elemento está totalmente visível na tela
      if (top >= 0 && bottom <= windowHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Adiciona um listener de scroll para verificar a visibilidade do elemento
    window.addEventListener('scroll', handleScroll);

    // Verifica a visibilidade ao carregar a página
    handleScroll();

    // Remove o listener de scroll ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function count() {
    for (let i = 0; i <= number; i++) {
      setTimeout(() => {
        setNum(i);
      }, i * 100);
    }
  }

  useEffect(() => {
    if (isVisible && num !== number) {
      count();
    }
  }, [isVisible]);

  return (
    <StyledContainer isVisible={isVisible} ref={ref}>
      <StyledNum>+{num}</StyledNum> <P size="32px" weight="bold">{text}</P>
    </StyledContainer>
  );
}

export { NumberAnimated };
export default NumberAnimated;
