import React from "react"
import styled from "styled-components"

import Container from "./Container"
import P from "./P"

import LOGO from "../assets/media/logo_nav.png"

const ColTitle = styled.h6`
    all: unset;
    font-weight: bold;
    font-size: 28px;
    margin: 0;

    @media only screen and (max-width: 600px) {
        font-size: 18px;
    }
`

const LogoContainer = styled.a`
    all: unset;
`

const LogoIcon = styled.img`
    margin-top: -130px;
    width: 120px;
    height: auto;
    transition: transform 0.5s ease-out;
    cursor: pointer;


    /* Animação ao passar o mouse sobre a imagem */
    &:hover {
        transform: translateX(20px);
    }
`

const Link = styled.a`
    all: unset;
    color: #fefefe;
    margin-top: 8px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
        font-weight: bold;
    }
`



function Footer () {
    return (
        <Container
            css="
                width: 100vw;
                height: 200px;
                background-color: #e72c71;
                display: flex;
                justify-content: center;
            "
        >
            <Container
                css="
                    width: 80vw;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                "
            >
                <Container
                    css="
                        display: flex;
                        justify-content: center;
                        align-items: left;
                        flex-direction: column;
                        width: 50%;

                        @media only screen and (max-width: 600px) {
                            width: 90vw;
                            align-items: center;
                        }
                    "
                >
                    <LogoContainer href="#top">
                        <LogoIcon src={LOGO}/>
                    </LogoContainer> 
                    <P color={"#fefefe"}>© 2024 MANGO ALL RIGHTS RESERVED.</P>
                
                </Container>
                
            </Container>

        </Container>
    )
}

export {Footer}
export default Footer