import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {ButtonLink} from "./ButtonLink"
import {Container} from "./Container"
import RenderIf from "./RenderIf"

import {useTamanhoTela} from "../hooks/useTamanhoTela"

import LOGO from "../assets/media/logo_nav.png"

const StyledNav = styled.nav`
    width: 100vw;
    padding-top: 1%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: #fefefe;
    opacity: 95%;
    left: 0;
    ${props => props.hasBoxShadow && 'box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);'} /* Aplica box-shadow se hasBoxShadow for verdadeiro */
`

const LogoContainer = styled.a`
    all: unset;
`

const LogoIcon = styled.img`
    width: 80px;
    height: auto;
    transition: transform 0.5s ease-out;
    cursor: pointer;

    /* Animação ao passar o mouse sobre a imagem */
    &:hover {
        transform: translateX(20px);
    }
`

const Links = styled.div`
    display: flex;
    justify-content: space-between;
    width: 30%;
    gap: 5%;
`

const Link = styled.a`
    all: unset;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: translateY(-6px) scale(1.05);
        border-bottom: 2px solid #e72c71;
        font-weight: bold;
    }
`

function Nav () {
    const {isMobile} = useTamanhoTela()
    const [hasBoxShadow, setHasBoxShadow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Verifica se a posição da página é maior que 0 (ou seja, não está no topo)
            const isNotTop = window.scrollY > 0;
            // Define o estado hasBoxShadow com base na posição da página
            setHasBoxShadow(isNotTop);
        };

        // Adiciona um event listener para monitorar o scroll da página
        window.addEventListener('scroll', handleScroll);

        // Remove o event listener ao desmontar o componente
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <StyledNav hasBoxShadow={hasBoxShadow}>
            <Container
                css="
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 80vw;
                margin: 0 auto;
                "
            >
                <LogoContainer href="#top">
                    <LogoIcon src={LOGO}/>
                </LogoContainer>  
            
                <ButtonLink>Solicitar orçamento</ButtonLink>

                <RenderIf condicao={!isMobile}>
                    <Links>
                        <Link href="#about">Sobre</Link>
                        <Link href="#services">Serviços</Link>
                        <Link href="#products">Produtos</Link>
                    </Links>
                </RenderIf>
            </Container>
        </StyledNav>
    )
}

export { Nav }
export default Nav
