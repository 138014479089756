import Spline from '@splinetool/react-spline';

function SplineObject({url}) {
  return (
    <Spline scene={url} />
  );
}

export {SplineObject}
export default SplineObject
