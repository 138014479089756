import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
    ${(props) => props.css}

    @
`

function Container ({ children, css, id }) {

    return (
        <StyledContainer id={id} css={css}> {children} </StyledContainer>
    )
}

export {Container}
export default Container