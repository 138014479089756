import React from "react"
import styled from "styled-components"

const StyledPreTitle = styled.h3`
    font-weight: 300;
    font-style: italic;
    font-size: 26px;
    color: #7a7a7a;
    margin: 0;
    text-align: left;

    @media only screen and (max-width: 600px) {
        font-size: 18px;
    }
`

function PreTitle ({children}) {
    return (
        <StyledPreTitle>
            {children}
        </StyledPreTitle>

    )
}

export {PreTitle}
export default PreTitle