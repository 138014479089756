import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home"
import { Analytics } from "@vercel/analytics/react";

function AppRouter() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home/>} />
        </Routes>
      </BrowserRouter>
      <Analytics />
    </>
  );
}

export default AppRouter;
