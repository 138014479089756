import React, {useState} from "react";
import styled from "styled-components";
import SplineObject from "./SplineObject";
import P from "./P";
import Container from "./Container";

const StyledRobotAction = styled.button`
    all: unset;
    cursor: pointer;
`

const SpeechBubble = styled.div`
    position: absolute;
    display: ${props =>props.show ? 'flex' : 'none'};
    flex-direction: column;
    width: 160px;
    z-index:2;
    top: -22%;
    right: 1.6%;
    border-radius: 12px 12px 0px 0px;
    padding: 8px 10px;
    background: rgba(255, 255, 255, 0.1); /* Cor de fundo com transparência */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(6px); /* Aplica o efeito de desfoque */
`;

const RobotMenuTitle = styled.h3`
    all: unset;
    font-weight: bold;
    font-size: 16px;
    color: #000;
`

const Link = styled.a`
    all: unset;
    margin-top: 8px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
        font-weight: bold;
    }
`

function RobotAction () {
    const [show, setShow] = useState(false)
    return (
        <Container
            css="
                width: 800px;
                height: 800px;
                position: fixed;
                top: 88%;
                left: -34%;
            "
        >
            <StyledRobotAction onClick={() => setShow(prev => !prev)}>
                <SpeechBubble show={show}>
                    <RobotMenuTitle>Menu do site</RobotMenuTitle>
                    <Link href="https://api.whatsapp.com/send?phone=8498220309&text=Olá,%20vim%20através%20do%20site%20da%20Mango." target="_blank">Solicitar orçamento</Link>
                    <Link href="#about">Sobre</Link>
                    <Link href="#services">Serviços</Link>
                    <Link href="#services">Produtos</Link>
                    <Link href="#services">Contato</Link>
                </SpeechBubble>
                <SplineObject url="https://prod.spline.design/DvuCeHrTUCDzIEiT/scene.splinecode"/>
            </StyledRobotAction>
        </Container>
    )
}

export {RobotAction}
export default RobotAction