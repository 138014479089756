import React from "react"
import styled from "styled-components"

const StyledTitle = styled.h2`
    font-weight: bold;
    font-size: 48px;
    margin: 0;

    @media only screen and (max-width: 600px) {
        font-size: 28px;
    }
`

function Title ({children}) {
    return (
        <StyledTitle>
            {children}
        </StyledTitle>

    )
}

export {Title}
export default Title