import React from "react"
import styled from "styled-components"


const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    height: 380px;
    padding: 20px 30px;
    // background-color: #e72c71;
    border-radius: 16px;
    background-image: url(${props=>props.bg});
    background-size: cover;
`

const CardTitle = styled.h3`
    font-size: 22px;
    color: #fefefe;
`

const Button = styled.a`
    all: unset;
    border-radius: 10px;
    background-color: #fefefe;
    color: #e72c71;
    font-weight: bold;
    cursor: pointer;
    padding 8px 16px;
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;

    &:hover {
        box-shadow: 0px 0px 0px 2px #e72c71;
    }
`

function Card ({children, title, bg, url}) {
    return (

        <StyledCard bg={bg}>
            <CardTitle>{title}</CardTitle>
            {children}
            <Button href={url} target="_blank">Quero um orçamento</Button>
           
        </StyledCard>

    )
}

export {Card}
export default Card