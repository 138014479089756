import React from "react"
import styled, {keyframes} from "styled-components"
import P from "./P";

const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const StyledStrap = styled.span`
    width: 100vw !important;
    height: 100px;
    background-color: #eb5931; /* Cor inicial do gradiente */
    background-image: linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045); /* Gradiente linear */
    background-size: 200% 200%; /* Tamanho do gradiente */
    animation: ${gradientAnimation} 6s ease infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    @media only screen and (max-width: 600px) {
        height: 140px;
    }
`

const EmptyContainer = styled.span`
    width: 70vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 90vw;
    }
`

const StyledButton = styled.a`
    all: unset;
    border: 3px solid #fefefe;
    color: #fefefe;
    padding: 8px 18px;
    height: 18px;
    width: 180px;
    cursor: pointer;
    text-align: center;
    transition: font-weight 0.3s ease;

    &:hover {
        font-weight: bold;
    }
`

function BudgetStrap () {

    return (

        <StyledStrap>
            <EmptyContainer>
                <P color="#fefefe" weight="bold">Não perca tempo, peça um orçamento agora clicando no botão seguinte.</P>
                <StyledButton href="https://api.whatsapp.com/send?phone=8498220309&text=Olá,%20vim%20através%20do%20site%20da%20Mango." target="_blank">Falar com especialista</StyledButton>
            </EmptyContainer>
        </StyledStrap>

    )

}

export {BudgetStrap}
export default BudgetStrap